import React, { FC } from 'react'

interface Props {
    width: number;// 60
    height: number;// 60
}

const Instagram: FC<Props> = ({ width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="30" fill="url(#paint0_linear_214_968)" />
            <path d="M35 30C35 30.9889 34.7068 31.9556 34.1573 32.7779C33.6079 33.6001 32.827 34.241 31.9134 34.6194C30.9998 34.9978 29.9945 35.0969 29.0245 34.9039C28.0546 34.711 27.1637 34.2348 26.4645 33.5355C25.7652 32.8363 25.289 31.9454 25.0961 30.9755C24.9031 30.0055 25.0022 29.0002 25.3806 28.0866C25.759 27.173 26.3999 26.3921 27.2221 25.8427C28.0444 25.2932 29.0111 25 30 25C31.3256 25.0015 32.5965 25.5288 33.5339 26.4661C34.4712 27.4035 34.9985 28.6744 35 30ZM45.625 23.125V36.875C45.6224 39.1948 44.6997 41.4189 43.0593 43.0593C41.4189 44.6997 39.1948 45.6224 36.875 45.625H23.125C20.8052 45.6224 18.5811 44.6997 16.9407 43.0593C15.3003 41.4189 14.3776 39.1948 14.375 36.875V23.125C14.3776 20.8052 15.3003 18.5811 16.9407 16.9407C18.5811 15.3003 20.8052 14.3776 23.125 14.375H36.875C39.1948 14.3776 41.4189 15.3003 43.0593 16.9407C44.6997 18.5811 45.6224 20.8052 45.625 23.125ZM37.5 30C37.5 28.5166 37.0601 27.0666 36.236 25.8332C35.4119 24.5999 34.2406 23.6386 32.8701 23.0709C31.4997 22.5032 29.9917 22.3547 28.5368 22.6441C27.082 22.9335 25.7456 23.6478 24.6967 24.6967C23.6478 25.7456 22.9335 27.082 22.6441 28.5368C22.3547 29.9917 22.5032 31.4997 23.0709 32.8701C23.6386 34.2406 24.5999 35.4119 25.8332 36.236C27.0666 37.0601 28.5166 37.5 30 37.5C31.9884 37.4978 33.8948 36.7069 35.3008 35.3008C36.7069 33.8948 37.4978 31.9884 37.5 30ZM40 21.875C40 21.5042 39.89 21.1416 39.684 20.8333C39.478 20.525 39.1851 20.2846 38.8425 20.1427C38.4999 20.0008 38.1229 19.9637 37.7592 20.036C37.3955 20.1084 37.0614 20.287 36.7992 20.5492C36.537 20.8114 36.3584 21.1455 36.286 21.5092C36.2137 21.8729 36.2508 22.2499 36.3927 22.5925C36.5346 22.9351 36.775 23.228 37.0833 23.434C37.3916 23.64 37.7542 23.75 38.125 23.75C38.6223 23.75 39.0992 23.5525 39.4508 23.2008C39.8025 22.8492 40 22.3723 40 21.875Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_214_968" x1="8" y1="55" x2="52.5" y2="11.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF7905" />
                    <stop offset="0.527751" stop-color="#E43F2A" />
                    <stop offset="1" stop-color="#9D07DD" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Instagram