import React, { FC } from 'react'

interface Props {
    width: number;
    height: number;
    fill?: string;
}

const Terminal: FC<Props> = ({ width, height, fill = "#00A8E8" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_211_45)">
                <path d="M112.5 100C112.5 101.382 111.382 102.5 110 102.5H10C8.62 102.5 7.5 101.382 7.5 100V20C7.5 18.62 8.62 17.5 10 17.5C11.38 17.5 12.5 18.62 12.5 20V97.5H110C111.382 97.5 112.5 98.6175 112.5 100ZM17.5 70V30C17.5 28.62 18.62 27.5 20 27.5H25V20C25 18.62 26.12 17.5 27.5 17.5C28.88 17.5 30 18.62 30 20V27.5H35C36.38 27.5 37.5 28.62 37.5 30V70C37.5 71.3825 36.38 72.5 35 72.5H30V80C30 81.3825 28.88 82.5 27.5 82.5C26.12 82.5 25 81.3825 25 80V72.5H20C18.62 72.5 17.5 71.3825 17.5 70ZM22.5 67.5H32.5V32.5H22.5V67.5ZM42.5 80V57.5C42.5 56.12 43.62 55 45 55H50V47.5C50 46.12 51.12 45 52.5 45C53.88 45 55 46.12 55 47.5V55H60C61.38 55 62.5 56.12 62.5 57.5V80C62.5 81.3825 61.38 82.5 60 82.5H55V90C55 91.3825 53.88 92.5 52.5 92.5C51.12 92.5 50 91.3825 50 90V82.5H45C43.62 82.5 42.5 81.3825 42.5 80ZM47.5 77.5H57.5V60H47.5V77.5ZM67.5 75V47.5C67.5 46.12 68.6175 45 70 45H75V37.5C75 36.12 76.1175 35 77.5 35C78.8825 35 80 36.12 80 37.5V45H85C86.3825 45 87.5 46.12 87.5 47.5V75C87.5 76.3825 86.3825 77.5 85 77.5H80V85C80 86.3825 78.8825 87.5 77.5 87.5C76.1175 87.5 75 86.3825 75 85V77.5H70C68.6175 77.5 67.5 76.3825 67.5 75ZM72.5 72.5H82.5V50H72.5V72.5ZM112.5 30V65C112.5 66.3825 111.382 67.5 110 67.5H105V75C105 76.3825 103.882 77.5 102.5 77.5C101.118 77.5 100 76.3825 100 75V67.5H95C93.6175 67.5 92.5 66.3825 92.5 65V30C92.5 28.62 93.6175 27.5 95 27.5H100V20C100 18.62 101.118 17.5 102.5 17.5C103.882 17.5 105 18.62 105 20V27.5H110C111.382 27.5 112.5 28.62 112.5 30ZM107.5 32.5H97.5V62.5H107.5V32.5Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_211_45">
                    <rect width={width} height={height} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Terminal