import React, { FC } from 'react'

interface Props {
    width: number; //16
    height: number; //16
    fill?: string;
}

const Mail: FC<Props> = ({ width, height, fill = "white" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_214_922)">
                <path d="M13.88 14.6H2.16C1 14.6 0.0400009 13.64 0.0400009 12.48V3.92005C0.0400009 2.76005 1 1.80005 2.16 1.80005H13.88C15.04 1.80005 16 2.76005 16 3.92005V12.44C16 13.64 15.04 14.6 13.88 14.6ZM2.16 2.60005C1.4 2.60005 0.800001 3.20005 0.800001 3.92005V12.44C0.800001 13.16 1.4 13.76 2.12 13.76H13.84C14.56 13.76 15.16 13.16 15.16 12.44V3.92005C15.16 3.20005 14.56 2.60005 13.84 2.60005H2.16Z" fill={fill} />
                <path d="M8 9.5599C7.32 9.5599 6.68 9.2399 6.2 8.7199L2.4 4.1599C2.24 3.9999 2.28 3.7599 2.44 3.5999C2.6 3.4399 2.88 3.4799 3 3.6399L6.84 8.2399C7.12 8.5999 7.56 8.7999 8 8.7999C8.44 8.7999 8.88 8.5999 9.16 8.2399L12.96 3.6799C13.12 3.5199 13.36 3.4799 13.52 3.6399C13.68 3.7999 13.72 4.0399 13.56 4.1999L9.76 8.7599C9.36 9.2799 8.72 9.5599 8 9.5599Z" fill={fill} />
                <path d="M14.2 12.72C14.08 12.72 13.96 12.68 13.88 12.56L11.12 9.28002C10.96 9.12002 11 8.84002 11.16 8.72002C11.32 8.56002 11.6 8.60002 11.72 8.76002L14.48 12.04C14.64 12.2 14.6 12.48 14.44 12.6C14.4 12.68 14.28 12.72 14.2 12.72Z" fill={fill} />
                <path d="M1.8 12.6801C1.72 12.6801 1.6 12.6401 1.56 12.6001C1.4 12.4401 1.36 12.2001 1.52 12.0401L4.24 8.80006C4.4 8.64006 4.64 8.60006 4.8 8.76006C4.96 8.92006 5 9.16006 4.84 9.32006L2.12 12.5601C2.04 12.6401 1.92 12.6801 1.8 12.6801Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_214_922">
                    <rect width={width} height={height} fill={fill} />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Mail