import { chartDataType, updateArrowsType, updateLinesType, updateRectanglesType, updateTextsType, updateTrianglesType, updateDataType } from "./types";
import { Line, Text, Arrow, Rectangle, Triangle, CandleClear } from '../../../modules/chart/types'

export const UPDATE_DATA = 'UPDATE_DATA'
export const UPDATE_RECTANGLES = 'UPDATE_RECTANGLES'
export const UPDATE_TRIANGLES = 'UPDATE_TRIANGLES'
export const UPDATE_ARROWS = 'UPDATE_ARROWS'
export const UPDATE_TEXTS = 'UPDATE_TEXTS'
export const UPDATE_LINES = 'UPDATE_LINES'

const defaultState: chartDataType = {
    data: [],
    rectangles: [],
    triangles: [],
    arrows: [],
    texts: [],
    lines: [],
};

export const chartDataReducer = (
    state: chartDataType = defaultState,
    action: any
): chartDataType => {
    switch (action.type) {
        case UPDATE_DATA:
            if (JSON.stringify(state.data) === JSON.stringify(action.value)) {
                return state;
            }
            return { ...state, data: action.value }
        case UPDATE_RECTANGLES:
            if (JSON.stringify(state.rectangles) === JSON.stringify(action.value)) {
                return state;
            }
            return { ...state, rectangles: action.value }
        case UPDATE_TRIANGLES:
            if (JSON.stringify(state.triangles) === JSON.stringify(action.value)) {
                return state;
            }
            return { ...state, triangles: action.value }
        case UPDATE_ARROWS:
            if (JSON.stringify(state.arrows) === JSON.stringify(action.value)) {
                return state;
            }
            return { ...state, arrows: action.value }
        case UPDATE_TEXTS:
            if (JSON.stringify(state.texts) === JSON.stringify(action.value)) {
                return state;
            }
            return { ...state, texts: action.value }
        case UPDATE_LINES:
            if (JSON.stringify(state.lines) === JSON.stringify(action.value)) {
                return state;
            }
            return { ...state, lines: action.value }
        default:
            return state;
    }
};

export const updateData = (value: CandleClear[]): updateDataType => {
    return { type: UPDATE_DATA, value: value }
}

export const updateRectangles = (value: Rectangle[]): updateRectanglesType => {
    return { type: UPDATE_RECTANGLES, value: value }
}

export const updateTriangles = (value: Triangle[]): updateTrianglesType => {
    return { type: UPDATE_TRIANGLES, value: value }
}

export const updateArrows = (value: Arrow[]): updateArrowsType => {
    return { type: UPDATE_ARROWS, value: value }
}

export const updateTexts = (value: Text[]): updateTextsType => {
    return { type: UPDATE_TEXTS, value: value }
}

export const updateLines = (value: Line[]): updateLinesType => {
    return { type: UPDATE_LINES, value: value }
}
