import { Link } from 'react-router-dom';

import Horse from '../../../assets/images/HorseKnight.png'

const Intro = () => {
  return (
    <div className="page padding">
      <div className='grid w-full grid-cols-1 lg:grid-cols-2 h-full justify-between items-center font-poppins text-text'>
        <div className='
            w-0 lg:w-[500px] 2xl:w-[732px]
            absolute lg:relative'>
          <img src={Horse} alt='horse-knight' />
        </div>
        <div className='flex flex-col gap-3 items-center lg:items-end relative'>
          <div className='
              text-[50px] sm:text-[80px] md:text-[108px] lg:text-[70px] xl:text-[80px] 2xl:text-[108px]
              font-semibold'>
            <span className='text-[#00FF7F]'>Chart</span>
            <span>Knight</span>
          </div>
          <div className='
            text-xs sm:text-xl md:text-2xl lg:text-lg xl:text-xl 2xl:text-2xl 
            font-normal'>
            Opportunity for everyone | Efficiency for the best
          </div>
          <div className='
            max-w-[330px] sm:max-w-[390px] md:max-w-[400px] lg:max-w-[400px] 2xl:max-w-[500px]
            text-center
            text-md
            font-extralight'>
            Unique instruments to increase efficiency of the
            professional traders and give a chance for amateurs
          </div>{/*
          <Link
             to='/chart'
            className='
              text-2xl sm:text-2xl lg:text-2xl 2xl:text-3xl
              font-light py-3 px-16 bg-[#00A8E8] rounded-lg hover:scale-110 transition-all duration-300 mt-12 cursor-pointer'>
            Try now
          </Link>*/}
        </div>
      </div>
    </div>
  );
};

export default Intro;
