import React, { FC } from 'react'

interface Props {
    width: number;// 60
    height: number;// 60
}

const FaceBook: FC<Props> = ({ width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="30" fill="#1877F2" />
            <path d="M31.6773 47.7539C31.5016 47.761 31.3891 47.775 31.2766 47.775C28.8719 47.775 26.4742 47.768 24.0695 47.782C23.732 47.782 23.6406 47.6906 23.6406 47.3531C23.6477 41.7563 23.6477 36.1524 23.6477 30.5555C23.6477 30.4078 23.6477 30.2602 23.6477 30.0703C22.3398 30.0703 21.0672 30.0703 19.7734 30.0703C19.7734 28.0735 19.7734 26.1117 19.7734 24.0938C19.907 24.0938 20.0336 24.0938 20.1531 24.0938C21.2078 24.0938 22.2625 24.0867 23.3172 24.1008C23.5844 24.1008 23.6547 24.0235 23.6547 23.7633C23.6406 22.1953 23.6406 20.6203 23.6547 19.0524C23.6617 17.9555 23.8375 16.8867 24.3438 15.8953C25.068 14.4961 26.2141 13.5891 27.6625 13.0266C28.8438 12.5625 30.0883 12.3938 31.3398 12.3727C33.3789 12.3445 35.418 12.3586 37.457 12.3516C37.6469 12.3516 37.8016 12.3445 37.8016 12.6117C37.7945 14.5453 37.7945 16.4789 37.7945 18.4125C37.7945 18.4336 37.7875 18.4547 37.7594 18.5461C37.6398 18.5461 37.5062 18.5461 37.3727 18.5461C36.107 18.5461 34.8414 18.5391 33.5758 18.5461C32.8445 18.5461 32.2539 18.8414 31.8813 19.4813C31.7688 19.6711 31.6984 19.9172 31.6984 20.1422C31.6773 21.3375 31.6984 22.5328 31.6844 23.7281C31.6844 24.0094 31.7688 24.0867 32.043 24.0867C33.8008 24.0727 35.5586 24.0797 37.3164 24.0797C37.457 24.0797 37.5906 24.0797 37.8297 24.0797C37.5836 26.0906 37.3445 28.0594 37.0984 30.0492C35.2703 30.0492 33.4914 30.0492 31.6773 30.0492C31.6773 35.9625 31.6773 41.8406 31.6773 47.7539Z" fill="white" />
        </svg>
    )
}

export default FaceBook