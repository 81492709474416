import { customizationOptionsType, updateCustomizationType, updateSizeType } from "./types";

export const UPDATE_CUSTOMIZATION = 'UPDATE_CUSTOMIZATION';
export const UPDATE_SIZE = 'UPDATE_SIZE'

const defaultState: customizationOptionsType = {
    layout_background_color: 'rgba(6, 16, 23, 255)',
    layout_text_color: 'rgba(99, 105, 110, 255)',

    grid_vertLines_color: 'rgba(9, 22, 31, 255)',
    grid_horzLines_color: 'rgba(9, 22, 31, 255)',

    crosshair_vertLine_color: 'rgba(161, 165, 168, 255)',
    crosshair_vertLine_labelBackgroundColor: 'rgba(6, 16, 23, 255)',
    crosshair_horzLine_color: 'rgba(161, 165, 168, 255)',
    crosshair_horzLine_labelBackgroundColor: 'rgba(6, 16, 23, 255)',

    series_wickUpColor: 'rgba(0, 255, 127, 255)',
    series_upColor: 'rgba(0, 255, 127, 255)',
    series_wickDownColor: 'rgba(211, 47, 47, 255)',
    series_downColor: 'rgba(211, 47, 47, 255)',

    poi_textColor: 'rgba(255, 255, 255, 255)',
    poi_font: '9px Poppins 400',

    poi_color_green: 'rgba(0, 255, 127, 255)',
    poi_color_red: 'rgba(211, 47, 47, 255)',

    range_color: 'rgba(232, 234, 250, 255)',

    arrow_color_green: 'rgba(0, 255, 127, 255)',
    arrow_color_red: 'rgba(211, 47, 47, 255)',

    line_color_green: 'rgba(0, 255, 127, 255)',
    line_color_red: 'rgba(211, 47, 47, 255)',

    triangle_color_green: 'rgba(0, 255, 127, 255)',
    triangle_color_red: 'rgba(211, 47, 47, 255)',

    width: 0,
    height: 0,

    visiblePageIndex: 0,
};

export const customizationOptionsReducer = (
    state: customizationOptionsType = defaultState,
    action: any
): customizationOptionsType => {
    switch (action.type) {
        case UPDATE_CUSTOMIZATION:
            return { ...state, [action.key]: action.value }
        case UPDATE_SIZE:
            return { ...state, [action.key]: action.value }
        default:
            return state;
    }
};

export const updateCustomization = (key: string, value: string): updateCustomizationType => {
    return { type: UPDATE_CUSTOMIZATION, key: key, value: value }
}

export const updateSize = (key: string, value: number): updateSizeType => {
    return { type: UPDATE_SIZE, key: key, value: value }
}
