import { ActiveIndicators, Asset } from "../../../modules/chart/types";
import { chartOptionsType, updateActiveIndicatorsType, updateAssetsType, updateAssetType, updateIntervalsType, updateIntervalType, updateServerStatusType } from "./types";

export const UPDATE_INTERVAL = 'UPDATE_INTERVAL';
export const UPDATE_INTERVALS = 'UPDATE_INTERVALS'
export const UPDATE_ASSET = 'UPDATE_ASSET'
export const UPDATE_ASSETS = 'UPDATE_ASSETS'
export const UPDATE_ACTIVE_INDICATORS = 'UPDATE_ACTIVE_INDICATORS'
export const UPDATE_SERVER_STATUS = 'UPDATE_SERVER_STATUS'

const defaultState: chartOptionsType = {
    interval: '',
    asset: {
        symbol: '',
        change: '',
        exchange: ''
    },
    intervals: [],
    assets: [],
    activeIndicators: {},
    isServerActive: 'TRY_TO_FETCH'
};

export const chartOptionsReducer = (
    state: chartOptionsType = defaultState,
    action: any
): chartOptionsType => {
    switch (action.type) {
        case UPDATE_INTERVAL:
            return { ...state, interval: action.value }
        case UPDATE_INTERVALS:
            const object: ActiveIndicators = {};

            action.value.forEach((interval: string) => {
                object[interval] = {
                    BOS: false,
                    IMB: false,
                    OB: false,
                    STB_BTS: false,
                    LIQ: false,
                    WICK: false,
                    BB: false,
                    SW: false,
                    RD: false,
                    HH_LL: false,
                    RANGE: false,
                };
            });
            return { ...state, intervals: action.value, activeIndicators: object }
        case UPDATE_ASSET:
            return { ...state, asset: action.value }
        case UPDATE_ASSETS:
            return { ...state, assets: action.value }
        case UPDATE_ACTIVE_INDICATORS:
            return { ...state, activeIndicators: action.value }
        case UPDATE_SERVER_STATUS:
            return { ...state, isServerActive: action.value }
        default:
            return state;
    }
};

export const updateServerStatus = (value: 'TRY_TO_FETCH' | 'ACTIVE' | 'NOT_ACTIVE'): updateServerStatusType => {
    return { type: UPDATE_SERVER_STATUS, value: value }
}

export const updateInterval = (value: string): updateIntervalType => {
    return { type: UPDATE_INTERVAL, value: value }
}

export const updateIntervals = (value: string[]): updateIntervalsType => {
    return { type: UPDATE_INTERVALS, value: value }
}

export const updateAssets = (value: Asset[]): updateAssetsType => {
    return { type: UPDATE_ASSETS, value: value }
}

export const updateAsset = (value: Asset): updateAssetType => {
    return { type: UPDATE_ASSET, value: value }
}

export const updateActiveIndicators = (value: ActiveIndicators): updateActiveIndicatorsType => {
    return { type: UPDATE_ACTIVE_INDICATORS, value: value }
}
