//import { useSelector } from 'react-redux';

//import { rootReducerType } from '../../../redux/store';

import Location from '../../../assets/images/contacts/Location';
import Mail from '../../../assets/images/contacts/Mail';
import Phone from '../../../assets/images/contacts/Phone';

import Discord from '../../../assets/images/social/Discord';
import FaceBook from '../../../assets/images/social/FaceBook';
import Instagram from '../../../assets/images/social/Instagram';
import LinkedIn from '../../../assets/images/social/LinkedIn';
import Logo from '../../../assets/images/Logo';

const AboutUs = () => {

  //const visiblePageIndex = useSelector((state: rootReducerType) => state.customizationOptions.visiblePageIndex)

  return (
    <div id="about_as" className="page padding pt-20 pb-20">
      <div className='grid justify-between text-text font-poppins items-center
      grid-cols-1 lg:grid-cols-[60%_40%] '>
        <div>
          <div className='text-[80px] md:text-[100px] leading-[80px] font-bold'>
            <div>ABOUT</div>
            <div className='text-[#00FF7F]'>US</div>
          </div>
          <div className='text-lg font-extralight max-w-[100%] md:max-w-[80%] mt-10'>
            We are a team of traders and tech specialists united by common challenges
            in the trading industry. We develop software solutions for trading,
            provide custom services for existing businesses, and also work
            on our own independent projects.
          </div>
          <div className='flex gap-4 mt-14'>
            <div className='hover:shadow-discord rounded-full cursor-pointer'>
              <a
                href="https://discord.gg/KYA6sMWH9x"
                target="_blank"
                rel="noopener noreferrer"
                className='justify-center items-center'
              >
                <Discord width={60} height={60} />
              </a>
            </div>
            <div className='hover:shadow-linked rounded-full cursor-pointer'>
              <a
                href="https://linkedin.com/in/chart-knight-a26047312"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn width={60} height={60} />
              </a>
            </div>
            <div className='hover:shadow-instagram rounded-full cursor-pointer'>
              <a
                href="https://www.instagram.com/chart.knight.app?igsh=MWRuaXB6NHF1NmJ1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram width={60} height={60} />
              </a>
            </div>
            <div className='hover:shadow-facebook rounded-full cursor-pointer'>
              <a
                href="https://www.facebook.com/profile.php?id=61560361241761"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaceBook width={60} height={60} />
              </a>
            </div>
          </div>
          <div className='text-2xl font-semibold mt-14'>Contact us</div>
          <div className='flex text-base font-extralight mt-10
          flex-col md:flex-row 
          gap-3 md:gap-16'>

            <div className='flex flex-row gap-3 items-center'>
              <Location width={16} height={16} />
              <div>Ukraine, Lviv</div>
            </div>
            {/* 
            <div className='flex flex-row gap-3 items-center'>
              <Phone width={16} height={16} />
              <div
                className='hover:text-text-active cursor-pointer'
                onClick={() => { navigator.clipboard.writeText('+38(097)5673456') }}>
                + 38 (097)  27  34  56
              </div>
            </div>
            */}
            <div className='flex flex-row gap-3 items-center'>
              <Mail width={16} height={16} />
              <div
                className='hover:text-text-active cursor-pointer'
                onClick={() => { navigator.clipboard.writeText('chart.knight.app@gmail.com') }}>
                chart.knight.app@gmail.com
              </div>
            </div>
          </div>
        </div>
        <div className='max-w-[500px] hidden lg:block'>
          <Logo />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
