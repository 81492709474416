import React, { FC } from 'react'

interface Props {
    width: number;// 60
    height: number;// 60
}

const LinkedIn: FC<Props> = ({ width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="29.5" cy="29.5" r="26.5" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30ZM21.2006 24.8472H14.402V45.2745H21.2006V24.8472ZM21.6485 18.5284C21.6044 16.5255 20.1721 15 17.8463 15C15.5205 15 14 16.5255 14 18.5284C14 20.4897 15.4756 22.0591 17.758 22.0591H17.8015C20.1721 22.0591 21.6485 20.4897 21.6485 18.5284ZM45.7193 33.5621C45.7193 27.2879 42.3652 24.3676 37.8914 24.3676C34.2817 24.3676 32.6657 26.3503 31.7634 27.7411V24.8479H24.9639C25.0535 26.7646 24.9639 45.275 24.9639 45.275H31.7634V33.8667C31.7634 33.2562 31.8075 32.6473 31.9872 32.2101C32.4786 30.9905 33.5974 29.7277 35.4756 29.7277C37.9368 29.7277 38.9206 31.6009 38.9206 34.3459V45.2745H45.719L45.7193 33.5621Z" fill="#0066C7" />
        </svg>
    )
}

export default LinkedIn