import Solution from './components/Solution';

import Analysis from '../../../../assets/images/solutions/Analysis';
import Education from '../../../../assets/images/solutions/Education';
import Screener from '../../../../assets/images/solutions/Screener';
import Terminal from '../../../../assets/images/solutions/Terminal';

const SVG_SIZE = 120;

const Solutions = () => {

  //const visiblePageIndex = useSelector((state: rootReducerType) => state.customizationOptions.visiblePageIndex)

  return (
    <div id="solutions" className='page padding grid items-center'>
      <div className=''>
        <div className='col-span-2 grid grid-rows-[auto_1fr]'>
          <div className='text-text  font-poppins font-bold text-left select-none
            text-4xl sm:text-7xl'>
            Our
            <span
              className='text-[#00FF7F] '>
              Solutions
            </span>
          </div>
        </div>
        <div className='grid w-full col-span-2 pt-12 gap-5 xl:gap-0
        grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 
        grid-rows-4 sm:grid-rows-2 xl:grid-rows-1'>
          <Solution svg={<Analysis width={SVG_SIZE} height={SVG_SIZE} />} title='Real-time analysis'>
            Your digital technical analisys specialist. Built on Smart Money concept, provides you with quality analysis in real time. 
          </Solution>
          <Solution svg={<Terminal width={SVG_SIZE} height={SVG_SIZE} />} title='Trading terminal'>
          Connect to your favoirite exchanges, manage your assets and trade with the very best tools industry has to offer.
          </Solution>
          <Solution svg={<Education width={SVG_SIZE} height={SVG_SIZE} />} title='EDUCATION'>
          Our educational course to start your trading journey. Complete interactive quests and earn rewards while learning.
          </Solution>
          <Solution svg={<Screener width={SVG_SIZE} height={SVG_SIZE}/>} hidden={true} title='DEALS screener'>
            Lorem ipsum dolor sit amet consectetur. Et adipiscing consequat vitae nibh libero
          </Solution>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
