import { createStore } from 'redux';
import { combineReducers } from 'redux';

import { customizationOptionsReducer } from './ducks/customizationOptions/customizationOptions';
import { chartDataReducer } from './ducks/chartData/chartData';
import { chartOptionsReducer } from './ducks/chartOptions/chartOptions';
import { userReducer } from './ducks/user/user';

import { customizationOptionsType } from './ducks/customizationOptions/types';
import { chartDataType } from './ducks/chartData/types';
import { chartOptionsType } from './ducks/chartOptions/types';
import { userType } from './ducks/user/types'

export type rootReducerType = {
    customizationOptions: customizationOptionsType,
    chartData: chartDataType,
    chartOptions: chartOptionsType,
    user: userType
}

const rootReducer = combineReducers({
    customizationOptions: customizationOptionsReducer,
    chartData: chartDataReducer,
    chartOptions: chartOptionsReducer,
    user: userReducer
});

export const store = createStore(rootReducer);
