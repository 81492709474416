import React, { FC } from 'react'

interface Props {
    width: number;// 12
    height: number;// 18
    fill?: string;
}

const Location: FC<Props> = ({ width, height, fill = "white" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 12 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M9.069 0.982549C7.10497 -0.327191 4.56727 -0.327191 2.60439 0.982549C0.0747607 2.66782 -0.735043 5.95196 0.720991 8.62097L5.8367 18L10.9524 8.62097C12.4084 5.95196 11.5998 2.66782 9.069 0.982549ZM9.94216 8.07035L5.83785 15.5959L1.73238 8.07035C0.56433 5.92777 1.21286 3.29331 3.24371 1.94095C4.03163 1.41567 4.93359 1.15188 5.8367 1.15188C6.73981 1.15188 7.64176 1.41452 8.42968 1.9398C10.4605 3.29331 11.1102 5.92777 9.94216 8.07035Z" fill={fill} />
            <path d="M5.83666 2.39941C4.24931 2.39941 2.95685 3.69188 2.95685 5.27923C2.95685 6.86658 4.24931 8.15905 5.83666 8.15905C7.42402 8.15905 8.71648 6.86658 8.71648 5.27923C8.71648 3.69188 7.42517 2.39941 5.83666 2.39941ZM5.83666 7.00712C4.88402 7.00712 4.10877 6.23187 4.10877 5.27923C4.10877 4.32659 4.88402 3.55134 5.83666 3.55134C6.78931 3.55134 7.56455 4.32659 7.56455 5.27923C7.56455 6.23187 6.78931 7.00712 5.83666 7.00712Z" fill={fill} />
        </svg>
    )
}

export default Location