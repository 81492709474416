import React, { FC } from 'react'

interface Props {
    width: number;
    height: number;
    fill?: string;
}

const Screener: FC<Props> = ({ width, height, fill = "#00A8E8" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M108.054 75.2272C106.765 75.2272 105.719 74.1819 105.719 72.8926V56.4837C105.719 53.8934 103.612 51.7862 101.022 51.7862C99.7324 51.7862 98.6871 50.7408 98.6871 49.4516C98.6871 48.1623 99.7324 47.1169 101.022 47.1169C106.187 47.1169 110.389 51.3191 110.389 56.4837V72.8926C110.389 74.1819 109.343 75.2272 108.054 75.2272Z" fill={fill} />
            <path d="M101.022 119.765H14.2898C9.12514 119.765 4.92303 115.563 4.92303 110.398L4.92444 35.2243C4.92444 33.935 5.96975 32.8896 7.25905 32.8896C8.54858 32.8896 9.59366 33.935 9.59366 35.2243L9.59225 110.398C9.59225 112.989 11.6995 115.096 14.2898 115.096H101.022C103.612 115.096 105.72 112.989 105.72 110.398V91.6454C105.72 90.3561 106.765 89.3107 108.054 89.3107C109.343 89.3107 110.389 90.3561 110.389 91.6454V110.398C110.389 115.563 106.187 119.765 101.022 119.765Z" fill={fill} />
            <path d="M112.742 93.9799H91.6169C86.468 93.9799 82.2787 89.7907 82.2787 84.6417V79.8963C82.2787 74.7473 86.468 70.5581 91.6169 70.5581H112.742C114.032 70.5581 115.077 71.6034 115.077 72.8927V91.6455C115.077 92.9346 114.032 93.9799 112.742 93.9799ZM91.6169 75.2271C89.0423 75.2271 86.9477 77.3217 86.9477 79.8963V84.6417C86.9477 87.2163 89.0423 89.3109 91.6169 89.3109H110.408V75.2273H91.6169V75.2271Z" fill={fill} />
            <path d="M93.989 84.604C92.6962 84.604 91.6544 83.547 91.6544 82.2694C91.6544 80.9944 92.6911 79.9343 93.989 79.9336C95.2811 79.9329 96.3236 80.9899 96.3236 82.2694C96.3236 83.5732 95.257 84.604 93.989 84.604Z" fill={fill} />
            <path d="M85.0163 79.9154H54.1493C52.86 79.9154 51.8147 78.8701 51.8147 77.5808V68.2044C51.8147 66.9151 52.86 65.8698 54.1493 65.8698C55.4386 65.8698 56.4839 66.9151 56.4839 68.2044V75.2462H85.0163C86.3056 75.2462 87.3509 76.2915 87.3509 77.5808C87.3509 78.8701 86.3056 79.9154 85.0163 79.9154Z" fill={fill} />
            <path d="M54.1493 98.6684C52.86 98.6684 51.8147 97.6231 51.8147 96.3338V86.9574C51.8147 85.6681 52.86 84.6228 54.1493 84.6228H85.0163C86.3056 84.6228 87.3509 85.6681 87.3509 86.9574C87.3509 88.2467 86.3056 89.292 85.0163 89.292H56.4837V96.3338C56.4837 97.6231 55.4384 98.6684 54.1493 98.6684Z" fill={fill} />
            <path d="M54.1399 70.5389C50.2675 70.5389 47.1171 67.3887 47.1171 63.5163C47.1171 59.644 50.2675 56.4935 54.1399 56.4935C58.0122 56.4935 61.1625 59.644 61.1625 63.5163C61.1625 67.3887 58.0122 70.5389 54.1399 70.5389ZM54.1399 61.1625C52.8419 61.1625 51.786 62.2184 51.786 63.5163C51.786 64.8141 52.8419 65.8699 54.1399 65.8699C55.4376 65.8699 56.4935 64.8141 56.4935 63.5163C56.4932 62.2184 55.4376 61.1625 54.1399 61.1625Z" fill={fill} />
            <path d="M39.008 89.292C35.1356 89.292 31.9852 86.1415 31.9852 82.2692C31.9852 78.3968 35.1356 75.2463 39.008 75.2463C42.8803 75.2463 46.0306 78.3968 46.0306 82.2692C46.0306 86.1413 42.8803 89.292 39.008 89.292ZM39.008 79.9153C37.71 79.9153 36.6542 80.9712 36.6542 82.2692C36.6542 83.5671 37.71 84.623 39.008 84.623C40.3057 84.623 41.3616 83.5671 41.3616 82.2692C41.3616 80.9712 40.3057 79.9153 39.008 79.9153Z" fill={fill} />
            <path d="M54.1399 108.045C50.2675 108.045 47.1171 104.894 47.1171 101.022C47.1171 97.1494 50.2675 93.9991 54.1399 93.9991C58.0122 93.9991 61.1625 97.1494 61.1625 101.022C61.1625 104.894 58.0122 108.045 54.1399 108.045ZM54.1399 98.6684C52.8419 98.6684 51.786 99.7242 51.786 101.022C51.786 102.32 52.8419 103.376 54.1399 103.376C55.4376 103.376 56.4935 102.32 56.4935 101.022C56.4932 99.724 55.4376 98.6684 54.1399 98.6684Z" fill={fill} />
            <path d="M34.3198 84.6035H7.25764C5.96835 84.6035 4.92303 83.5582 4.92303 82.2689C4.92303 80.9796 5.96835 79.9343 7.25764 79.9343H34.3195C35.6088 79.9343 36.6541 80.9796 36.6541 82.2689C36.6541 83.5582 35.6088 84.6035 34.3198 84.6035Z" fill={fill} />
            <path d="M54.1399 37.7215H49.4517C48.1624 37.7215 47.1171 36.6762 47.1171 35.3869C47.1171 34.0976 48.1624 33.0522 49.4517 33.0522H54.1399C55.4376 33.0522 56.4935 31.9964 56.4935 30.6987C56.4935 29.4007 55.4376 28.3448 54.1399 28.3448C50.2675 28.3448 47.1171 25.1944 47.1171 21.322C47.1171 17.4497 50.2675 14.2994 54.1399 14.2994H58.8278C60.1171 14.2994 61.1625 15.3448 61.1625 16.634C61.1625 17.9233 60.1171 18.9687 58.8278 18.9687H54.1399C52.8419 18.9687 51.786 20.0245 51.786 21.3223C51.786 22.6202 52.8419 23.6761 54.1399 23.6761C58.0122 23.6761 61.1625 26.8265 61.1625 30.6989C61.1625 34.5712 58.0122 37.7215 54.1399 37.7215Z" fill={fill} />
            <path d="M54.1399 18.9688C52.8506 18.9688 51.8053 17.9234 51.8053 16.6341V11.9459C51.8053 10.6566 52.8506 9.61133 54.1399 9.61133C55.4292 9.61133 56.4745 10.6566 56.4745 11.9459V16.6341C56.4745 17.9234 55.4292 18.9688 54.1399 18.9688Z" fill={fill} />
            <path d="M54.1399 42.4097C52.8506 42.4097 51.8053 41.3644 51.8053 40.0751V35.3869C51.8053 34.0976 52.8506 33.0522 54.1399 33.0522C55.4292 33.0522 56.4745 34.0976 56.4745 35.3869V40.0751C56.4745 41.3644 55.4292 42.4097 54.1399 42.4097Z" fill={fill} />
            <path d="M101.022 51.7861H21.3223C12.2798 51.7861 4.92328 44.4295 4.92328 35.3869C4.92328 26.3191 12.2616 18.9877 21.3223 18.9877H30.2323C31.5216 18.9877 32.5669 20.033 32.5669 21.3223C32.5669 22.6116 31.5216 23.6569 30.2323 23.6569H21.3223C14.8362 23.6569 9.5925 28.9055 9.5925 35.3869C9.5925 41.8549 14.8544 47.1169 21.3223 47.1169H98.6873V23.6566H78.0476C76.7583 23.6566 75.713 22.6113 75.713 21.322C75.713 20.0327 76.7583 18.9874 78.0476 18.9874H101.022C102.311 18.9874 103.357 20.0327 103.357 21.322V49.4515C103.356 50.7408 102.311 51.7861 101.022 51.7861Z" fill={fill} />
            <path d="M64.6343 51.7861H43.6453C43.3008 51.7861 42.9605 51.7099 42.649 51.563C33.4144 47.205 27.1922 37.8506 27.1922 27.1826C27.1922 12.3581 39.1212 0.234863 54.1399 0.234863C69.1704 0.234863 81.0874 12.3715 81.0874 27.1826C81.0874 37.8511 74.865 47.205 65.6306 51.563C65.3189 51.7099 64.9788 51.7861 64.6343 51.7861ZM44.1823 47.1169H64.0971C71.5992 43.362 76.4182 35.5938 76.4182 27.1826C76.4182 14.9267 66.5569 4.90408 54.1399 4.90408C41.7319 4.90408 31.8614 14.9152 31.8614 27.1826C31.8612 35.5938 36.6802 43.362 44.1823 47.1169Z" fill={fill} />
        </svg>
    )
}

export default Screener