import { BrowserRouter, Routes, Route } from "react-router-dom";

import ChartPage from './modules/chart/ChartPage';
import Home from './modules/home/Home';
import Auth from "./modules/auth/Auth";
import useRefreshToken from "./hooks/user/useRefreshToken";
import WorkInProgress from "./components/WorkInProgress";

function App() {
  //useRefreshToken()

  return (
    <div>
    <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          {/* 
          <Route path="chart" element={<ChartPage />} />
          
          <Route path="auth" element={<Auth />} />
          */}

          <Route path="*" element={<Home />} />
        </Routes>
    </BrowserRouter>
    {/*
    <WorkInProgress />
     */}
    </div>
  );
}

export default App;
