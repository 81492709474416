import React, { FC } from 'react'

interface Props {
    width: number;
    height: number;
    fill?: string;
}

const Education: FC<Props> = ({ width, height, fill = "#00A8E8" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_211_56)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M110 45L60 78.75L10 45L60 15L110 45Z" stroke={fill} stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M92.5 60V80.3411C92.5 85.5643 90.4567 90.5802 86.8069 94.3166C79.8497 101.439 70.9141 105 60 105C49.0859 105 40.1503 101.439 33.193 94.3166C29.5433 90.5802 27.5 85.5643 27.5 80.3412V60" stroke={fill} stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M110 45V65" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_211_56">
                    <rect width="120" height="120" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Education