import NavBar from './components/NavBar';
import Intro from './pages/Intro'
import FullPageScroll from './components/FullPageScroll';
import useLoadUser from '../../hooks/user/useLoadUser';
import AboutUs from './pages/AboutUs';

import './index.css'
import Solutions from './pages/solutions/Solutions';

const Home = () => {
  useLoadUser()
  return (
    <div className='bg-bg'>
      <NavBar />
        <div>
          <Intro />
        </div>
        <div>
          <Solutions />
        </div>
        <div>
          <AboutUs />
        </div>
    </div>
  );
};

export default Home;
