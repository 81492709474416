// hooks/useLoadUser.ts
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { USER_LOCAL_STORAGE, logIn } from '../../redux/ducks/user/user';
import { useNavigate } from 'react-router-dom';
import { rootReducerType } from '../../redux/store';

const useLoadUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector(
    (state: rootReducerType) => state.user.accessToken,
  );

  useEffect(() => {
    // Check localStorage for the user
    const storedUser = localStorage.getItem(USER_LOCAL_STORAGE);

    if (!accessToken) {
      if (storedUser) {
        try {
          const user = JSON.parse(storedUser);
          if (user && user.email && user.password) {
            // Dispatch the action to set the user in Redux
            dispatch(logIn(user, true));
            navigate('/chart', { replace: true });
          }
        } catch (error) {
          console.error('Error parsing user from localStorage:', error);
        }
      }
    }
    
  }, [dispatch, navigate, accessToken]);
};

export default useLoadUser;
