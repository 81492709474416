import { useDispatch, useSelector } from 'react-redux';

import { updateSize } from '../../../redux/ducks/customizationOptions/customizationOptions';

import { rootReducerType } from '../../../redux/store';

import MaskedLogo from '../../../components/MaskedSvg';

import KnightLogo from '../../../assets/images/Knight.svg';
import User from '../../auth/components/User';

const NavBar = () => {
  const visiblePageIndex = useSelector(
    (state: rootReducerType) => state.customizationOptions.visiblePageIndex,
  );
  const dispatch = useDispatch();

  const handleClick = (pageNumber: number) => {
    dispatch(updateSize('visiblePageIndex', pageNumber))
  }

  return (

    <div className="fixed bg-bg w-full backdrop-blur-sm bg-opacity-50 z-50 h-20 select-none padding">
      <div className="w-full h-full grid grid-cols-1 items-center">
        <div className="flex flex-row ml-auto 
            mr-2 lg:mr-14
            gap-10 sm:gap-20 md:gap-24
            text-xs sm:text-lg ">
          <div className="grid items-center">
            <div
              onClick={() => handleClick(1)}
              className="text-text font-poppins cursor-pointer hover:scale-110 transition-all duration-300">
              <a href="#solutions">
                Our Solutions
              </a>
            </div>
          </div>
          <div className="grid items-center">
            <div
              onClick={() => handleClick(2)}
              className="text-text font-poppins cursor-pointer hover:scale-110 transition-all duration-300">
              <a href="#about_as">
                About Us
              </a>
            </div>
          </div>
          {/* 
          <div className="grid items-center">
            <User />
          </div>
          */}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
