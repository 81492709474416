import React, { FC } from 'react'

interface Props {
    width: number;
    height: number;
    fill?: string;
}

const Analysis: FC<Props> = ({ width, height, fill = "#00A8E8" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M65.4774 56.5453L53.3157 41.5453C54.1707 40.2147 54.6517 38.6784 54.7081 37.0978C54.7645 35.5172 54.3943 33.9505 53.6362 32.5624C52.8782 31.1742 51.7604 30.0158 50.4002 29.2088C49.04 28.4017 47.4875 27.9758 45.9059 27.9758C44.3243 27.9758 42.7718 28.4017 41.4116 29.2088C40.0514 30.0158 38.9335 31.1742 38.1755 32.5624C37.4175 33.9505 37.0473 35.5172 37.1037 37.0978C37.1601 38.6784 37.6411 40.2147 38.4961 41.5453L21.3446 62.6953C20.6622 62.4775 19.9514 62.3622 19.2352 62.3531C17.7831 62.3526 16.3636 62.7828 15.1561 63.5892C13.9486 64.3956 13.0073 65.542 12.4514 66.8835C11.8956 68.2249 11.75 69.701 12.0331 71.1252C12.3163 72.5494 13.0155 73.8576 14.0422 74.8843C15.069 75.911 16.3772 76.6102 17.8013 76.8934C19.2255 77.1765 20.7016 77.031 22.0431 76.4751C23.3845 75.9192 24.5309 74.978 25.3373 73.7705C26.1437 72.5629 26.5739 71.1434 26.5735 69.6913C26.5619 68.4652 26.2394 67.262 25.636 66.1945L42.7922 45.0468C44.7789 45.8291 46.9883 45.8291 48.975 45.0468L61.1438 60.0468C62.3564 58.6186 63.8264 57.4309 65.4774 56.5453Z" fill={fill} />
            <path d="M72.5649 60.2344C70.6956 60.2367 68.869 60.793 67.3158 61.8331C65.7626 62.8732 64.5526 64.3504 63.8387 66.0779C63.1247 67.8055 62.9389 69.7059 63.3046 71.539C63.6704 73.3722 64.5713 75.0557 65.8936 76.377C67.2158 77.6983 68.9001 78.598 70.7335 78.9624C72.5669 79.3268 74.4672 79.1395 76.1942 78.4243C77.9212 77.709 79.3975 76.4979 80.4364 74.944C81.4753 73.3901 82.0303 71.563 82.0313 69.6937C82.0294 67.1843 81.0312 64.7783 79.2561 63.0045C77.481 61.2308 75.0743 60.2344 72.5649 60.2344Z" fill={fill} />
            <path d="M108.413 94.1555H7.01252C6.28958 94.1765 5.60329 94.4785 5.09936 94.9972C4.59543 95.516 4.31354 96.2108 4.31354 96.934C4.31354 97.6573 4.59543 98.352 5.09936 98.8708C5.60329 99.3896 6.28958 99.6915 7.01252 99.7125H108.413C109.135 99.6915 109.822 99.3896 110.326 98.8708C110.83 98.352 111.112 97.6573 111.112 96.934C111.112 96.2108 110.83 95.516 110.326 94.9972C109.822 94.4785 109.135 94.1765 108.413 94.1555Z" fill={fill} />
            <path d="M108.42 66.9164H93.9211C93.1845 66.9164 92.4781 67.209 91.9572 67.7298C91.4364 68.2507 91.1437 68.9571 91.1437 69.6937C91.1437 70.4303 91.4364 71.1368 91.9572 71.6576C92.4781 72.1785 93.1845 72.4711 93.9211 72.4711H108.42C109.156 72.4711 109.863 72.1785 110.383 71.6576C110.904 71.1368 111.197 70.4303 111.197 69.6937C111.197 68.9571 110.904 68.2507 110.383 67.7298C109.863 67.209 109.156 66.9164 108.42 66.9164Z" fill={fill} />
            <path d="M99.225 81.6446C99.9479 81.6236 100.634 81.3216 101.138 80.8028C101.642 80.284 101.924 79.5893 101.924 78.866C101.924 78.1428 101.642 77.448 101.138 76.9292C100.634 76.4105 99.9479 76.1085 99.225 76.0875H93.9211C93.1981 76.1085 92.5119 76.4105 92.0079 76.9292C91.504 77.448 91.2221 78.1428 91.2221 78.866C91.2221 79.5893 91.504 80.284 92.0079 80.8028C92.5119 81.3216 93.1981 81.6236 93.9211 81.6446H99.225Z" fill={fill} />
            <path d="M62.1094 32.6882H76.6055C77.3421 32.6882 78.0485 32.3956 78.5693 31.8748C79.0902 31.3539 79.3828 30.6475 79.3828 29.9109C79.3828 29.1743 79.0902 28.4679 78.5693 27.947C78.0485 27.4262 77.3421 27.1335 76.6055 27.1335H62.1094C61.3728 27.1335 60.6664 27.4262 60.1455 27.947C59.6246 28.4679 59.332 29.1743 59.332 29.9109C59.332 30.6475 59.6246 31.3539 60.1455 31.8748C60.6664 32.3956 61.3728 32.6882 62.1094 32.6882Z" fill={fill} />
            <path d="M62.1094 41.8617H67.4156C68.1522 41.8617 68.8587 41.5691 69.3795 41.0482C69.9004 40.5274 70.193 39.8209 70.193 39.0843C70.193 38.3478 69.9004 37.6413 69.3795 37.1205C68.8587 36.5996 68.1522 36.307 67.4156 36.307H62.1094C61.3728 36.307 60.6664 36.5996 60.1455 37.1205C59.6246 37.6413 59.332 38.3478 59.332 39.0843C59.332 39.8209 59.6246 40.5274 60.1455 41.0482C60.6664 41.5691 61.3728 41.8617 62.1094 41.8617Z" fill={fill} />
            <path d="M99 27.25C91.9609 27.25 86.25 32.9609 86.25 40C86.25 47.0391 91.9609 52.75 99 52.75C106.039 52.75 111.75 47.0391 111.75 40C111.75 32.9609 106.039 27.25 99 27.25Z" stroke={fill} stroke-width="3.5625" stroke-miterlimit="10" />
            <path d="M99 31.5V41.0625H105.375" stroke={fill} stroke-width="3.5625" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Analysis