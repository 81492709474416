import { logInType, logOutType, refreshType, userType } from './types';

export const USER_LOCAL_STORAGE = 'USER';

export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const REFRESH = 'REFRESH';

const defaultState: userType = {
  email: null,
  password: null,
  accessToken: null,
  refreshToken: null,
};

export const userReducer = (
  state: userType = defaultState,
  action: any,
): userType => {
  switch (action.type) {
    case LOG_IN:
      if (action.rememberMe) {
        localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(action.user));
      } else {
        localStorage.removeItem(USER_LOCAL_STORAGE);
      }
      return { ...action.user };
    case LOG_OUT:
      return { ...defaultState };
    case REFRESH:
      return { 
        ...state,
        accessToken: action.accessToken, 
        refreshToken: action.refreshToken 
      };
    default:
      return state;
  }
};

export const logIn = (user: userType, rememberMe: boolean): logInType => {
  return { type: LOG_IN, user: user, rememberMe };
};

export const logOut = (): logOutType => {
  return { type: LOG_OUT };
};

export const refreshTokens = (accessToken: string, refreshToken: string): refreshType => {
  return { type: REFRESH, accessToken: accessToken, refreshToken: refreshToken };
};
