import React from 'react'

type SolutionProps = {
    svg: React.ReactNode;
    title: string;
    children: React.ReactNode;
    hidden?: boolean;
};

const Solution: React.FC<SolutionProps> = ({ svg, title, children, hidden = false }) => {
    return (
        <div className="
            mx-auto
            w-[250px] sm:w-[300px] md:w-[300px] lg:w-[370px] xl:w-[250px] 2xl:w-[300px] relative grid grid-rows-[auto_auto_auto] text-text py-4 hover:scale-110 transition-all duration-300 select-none
            font-poppins bg-gradient-to-br from-[#061017] to-[#044E6C] border-2 border-[#044E6C] rounded-lg gap-3">
            <div className='mx-auto'>
                {svg}
            </div>
            <div className='text-center uppercase font-bold text-md sm:text-2xl' >
                {title}
            </div>
            <div className='relative text-center font-extralight text-xs sm:text-lg w-[80%] mx-auto'>
                {hidden &&
                    <div className='backdrop-blur-md w-full h-full top-0 bottom-0 absolute rounded-lg grid items-center'>
                        <span className='text-2xl font-bold'><span className='text-[#00FF7F]'>Coming</span> Soon</span>
                    </div>
                }
                {children}
            </div>
        </div>
    )
}

export default Solution
